import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@commons/components/modals/confirm-modal/confirm-modal.component';
import { CompanyComponent } from '@features/company/company.component';
import { JobDetailComponent } from '@features/jobs/job-detail/job-detail.component';
import { RecruiterDetailComponent } from '@features/recruiters/recruiter-detail/recruiter-detail.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum FormStatusWhenLeavedEnum {
  pristine = 'pristine',
  valid = 'valid',
  invalid = 'invalid',
}

export interface CanComponentDeactivate {
  canDeactivate(): FormStatusWhenLeavedEnum;
}

@Injectable()
export class FormGuard {
  readonly #modalDialog = inject(MatDialog);

  openDialog(isFormValid: boolean): MatDialogRef<ConfirmModalComponent> {
    return this.#modalDialog.open(ConfirmModalComponent, {
      data: {
        title: `Le formulaire n'est pas ${isFormValid ? 'sauvegardé' : 'complet'}`,
        subtitle: 'Si vous quittez la page, les modifications apportées ne seront pas sauvegardées.',
        choiceWording: {
          cancel: `${isFormValid ? 'Sauvegarder' : 'Compléter'} le formulaire`,
          confirm: 'Quitter',
        },
      },
    });
  }

  canDeactivate(
    component: CompanyComponent | RecruiterDetailComponent | JobDetailComponent
  ): Observable<boolean> | boolean {
    const formStatus = component.canDeactivate();

    if (formStatus === FormStatusWhenLeavedEnum.pristine) return true;

    const isFormValid = formStatus === FormStatusWhenLeavedEnum.valid;

    return this.openDialog(isFormValid)
      .afterClosed()
      .pipe(
        map((result) => result.confirmed),
        map((isConfirmed) => {
          if (!isConfirmed && isFormValid) {
            component.save();
            return true;
          }
          return isConfirmed;
        })
      );
  }
}
