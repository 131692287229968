import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ConfirmModal } from '@models/modals/confirm-modal';

export type ConfirmModalResult = { confirmed: boolean } | undefined;

@Component({
  standalone: true,
  selector: 'app-confirm-modal',
  imports: [MatButtonModule, MatIcon, MatDialogModule],
  providers: [MatDialog],
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
  readonly dialogRef = inject(MatDialogRef<ConfirmModalComponent, ConfirmModalResult>);
  readonly data = inject<ConfirmModal>(MAT_DIALOG_DATA);

  get confirmWording(): string {
    return this.data.choiceWording?.confirm || 'Oui';
  }

  get cancelWording(): string {
    return this.data.choiceWording?.cancel || 'Non';
  }

  get iconStyle(): string {
    return `background-color: ${this.data?.icon?.background ?? 'white'}; color: ${this.data?.icon?.color}`;
  }

  onConfirm(): void {
    this.dialogRef.close({ confirmed: true });
  }

  onCancel(): void {
    this.dialogRef.close({ confirmed: false });
  }
}
