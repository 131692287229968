import {
  City,
  DomainEnum,
  OpsJobEffectiveDurationEnum,
  OpsJobExperienceEnum,
  OpsJobStatusEnum,
} from '@wizbii/utils/models';

// Legacy : All const, types and interfaces in this file also exists in ops-event-bundle and sourcii

export const SALARY_PERIOD_OPTIONS = [
  { label: 'Annuel', value: 'yearly' },
  { label: 'Mensuel', value: 'monthly' },
  { label: 'Horaire', value: 'hourly' },
] as const;
export type SalaryPeriod = (typeof SALARY_PERIOD_OPTIONS)[number]['value'];

interface SalaryRange {
  minimum: number;
  maximum: number;
  /** @default "yearly" */
  salaryPeriod: SalaryPeriod;
}

export interface OpsJob {
  id: string;
  name: string;
  contractIds: string[];
  contractDuration: number | null;
  effectiveDuration: OpsJobEffectiveDurationEnum;
  effectiveHoursPerWeek?: number;
  degreeId: string;
  domain: DomainEnum;
  experience: OpsJobExperienceEnum;
  driversLicenceRequired: boolean;
  startDate: Date;
  dateCreated: Date;
  locations: City[];
  applicationMail: string;
  openPositions: number;
  requiredCandidates: number;
  confirmedCandidates: number;
  invitedCandidates: number;
  missions: string;
  qualities: string[];
  languages: string[];
  softwareSkills: string[];
  goodPoints: string;
  salaryRange: SalaryRange;
  status?: OpsJobStatusEnum;
  confidential?: boolean;
  comment?: string;
  offerExternalReference?: string;
}
