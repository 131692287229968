import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { JOB_OFFER_STATUS_DISPLAY } from '@domains/job-offer/job-offer-card/job-offer-status-display';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { JobsRoutingEnum } from '@features/jobs/jobs-routing.enum';
import { JobOfferStatusDisplay } from '@models/job-offer-status-display';
import { OpsJob } from '@models/ops-job';
import { Store } from '@ngxs/store';
import { CompanyParticipationState } from '@stores/company-participation/company-participation.state';
import { OpsJobStatusEnum } from '@wizbii/utils/models';

@Component({
  standalone: true,
  selector: 'app-job-offer-card',
  imports: [MatIcon, RouterModule],
  templateUrl: './job-offer-card.component.html',
  styleUrls: ['./job-offer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobOfferCardComponent {
  readonly jobOffer = input<OpsJob>();

  readonly #store = inject(Store);
  readonly isJobEditionAllowed = selectIfDefinedSig(this.#store, CompanyParticipationState.isJobEditionAllowed);

  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly JobsRoutingEnum = JobsRoutingEnum;

  get jobStatus(): JobOfferStatusDisplay {
    const jobOffer = this.jobOffer();

    if (!jobOffer) {
      return JOB_OFFER_STATUS_DISPLAY[OpsJobStatusEnum.STATUS_VALIDATED];
    }

    return JOB_OFFER_STATUS_DISPLAY[jobOffer?.status ?? OpsJobStatusEnum.STATUS_VALIDATED];
  }
}
